@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;500;600;700&display=swap");

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  transition: 0.4s;
}

// VARIABLES START
$color1: #ff6100;
$color2: #1a1b1c;
$color3: #504f4f;
$color4: #160d0d;
$color5: #606060;
// VARIABLES END

// GLOBAL START
html {
  width: 100%;
  font-size: 100%;
  overflow-x: hidden;
}
@media screen and (max-width: 992px) {
  html {
    font-size: 92%;
  }
}
@media screen and (max-width: 668px) {
  html {
    font-size: 86%;
  }
}
@media screen and (max-width: 575px) {
  html {
    font-size: 82%;
  }
}
@media screen and (max-width: 475px) {
  html {
    font-size: 78%;
  }
}
@media screen and (max-width: 380px) {
  html {
    font-size: 75%;
  }
}
@media screen and (max-width: 280px) {
  html {
    font-size: 72%;
  }
}

.pointer {
  cursor: pointer;
}

body {
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(90.23deg, #ff6100 0%, #29d6dd 100%);
  }
}

.f8 {
  font-size: 0.5rem;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875rem;
}

.f15 {
  font-size: 0.938rem;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f20 {
  font-size: 1.25rem;
}

.f22 {
  font-size: 1.375rem;
}

.f24 {
  font-size: 1.5rem;
}

.f28 {
  font-size: 1.75rem;
}

.f34 {
  font-size: 2.125rem;
}

.f30 {
  font-size: 1.875rem;
}

.f35 {
  font-size: 2.125rem;
  line-height: 57px;
}

.f50 {
  font-size: 3.125rem;
  line-height: 65px;
}

.f64 {
  font-size: 4rem;
  line-height: 71px;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.page_container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

body {
  // position: fixed;
  // top: 0%;
  // left: 0%;
  // bottom: 0%;
  // right: 0%;
  background: url("../../public/assets/body-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

// COLORS
.color1 {
  color: $color1;
}

.bg_color1 {
  background: $color1;
}

.color2 {
  color: $color2;
}

.bg_color2 {
  background: $color2;
}

.color3 {
  color: $color3;
}

.bg_color3 {
  background: $color3;
}

.color4 {
  color: $color4;
  font-weight: 600;
}

.bg_color4 {
  background: $color4;
}

.color5 {
  color: $color5;
}

.bg_color5 {
  background: $color5;
}

// HEADER START
.header_container {
  background: linear-gradient(90.23deg, #ff6100 0%, #29d6dd 100%);

  h1 {
    font-weight: 700;
  }
}

.top_navigation {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  z-index: 3;
}

.under_navigation {
  height: 97px;
}
// HEADER END

// UP HERO START
.up_hero_container {
  background: url("../../public/assets/body-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 97px);
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  .lets_start {
    background: $color1;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    backdrop-filter: blur(50px);
    color: #ffffff;
    border-radius: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 0.5rem 2rem;
  }
  h3 {
    font-weight: 700;
    padding-top: 50px;
    font-family: "Ibarra Real Nova", serif !important;
    // color: #ff8811 !important;
    // text-shadow: 2px 2px 2px #000000;
    h5 {
      display: inline-block;

      font-size: inherit;
      font-weight: inherit;
      font-family: inherit !important;
    }
  }

  .position-relative {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 125px);
  }

  .trnsact_with_container {
    position: absolute;
    bottom: 10%;
    left: 0%;
    background: #fff;
    padding: 1rem 2rem;
    width: 90%;
    border-radius: 0px 10px 10px 0px;
  }
}
// UP HERO END

// NAVIGATION START
.navigation_container {
  background: #1a1b1c;

  .active_navlink {
    background: $color1;
  }

  .header_btns {
    button:nth-child(2) {
      background: #ff620051;
      border: 2px solid $color1;
      border-radius: 4px;
      color: #fff;
    }
  }

  .hamb_cross {
    width: 35px;
  }
}
// NAVIGATION END

// HERO START
.hero_container {
  .mySwiper {
    margin-top: 2rem;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: $color1;

    .single_swiper {
      padding: 3rem;
      min-height: 700px;
      display: flex;
      align-items: center;

      img[alt="Get 80% Loan on your Crypto Assets"] {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: flex;
        justify-content: center;
      }

      img {
        width: 100%;
      }

      .lets_start {
        background: rgba(255, 255, 255, 0.2);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        backdrop-filter: blur(50px);
        color: #ffffff;
        border-radius: 10px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        padding: 0.5rem 2rem;
      }
    }
    .swiper-pagination-bullet {
      background: #fff;
      opacity: 1;
      margin: 0px 3px !important;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 44px;
      border-radius: 50px;
    }
  }
}
// HERO END

// TRANSACT START
.transact_container {
  margin-top: 1rem;
}
// TRANSACT END

// CONTACT START
.contact_container {
  margin-top: 1rem;

  input {
    height: 50px;
  }

  .submit {
    background: rgba(255, 255, 255, 0.2);
    border: 2px solid #ffffff;
    box-sizing: border-box;
    backdrop-filter: blur(50px);
    color: #ffffff;
    border-radius: 6px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 0.5rem 2rem;
  }
}
// CONTACT END

// FOOTER START
.footer_container {
  margin-top: 2rem;
  background: linear-gradient(90.23deg, #ff6100 0%, #29d6dd 100%);
  padding: 2rem 0rem;
}
// FOOTER END

// HOW IT WORKS START
.how_it_works {
  background: #ffffff;
  border-radius: 10px;
  min-height: 1px;
  height: 100%;
}
// HOW IT WORKS END

// SUBMIT PGAE START
.thankyou_container {
  margin-top: 4rem;
  margin-bottom: 4rem;

  .thankyou_container_inner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    min-height: calc(100vh - 225px);
    margin-top: 5rem;
    padding: 2rem 0rem;
  }
}
// SUBMIT PGAE END

/* ==================== SideBAR STYLING END ======================== */
.SideBarContainer {
  margin-top: 97px;
  position: fixed;
  right: 0%;
  left: 0%;
  bottom: 0%;
  color: white;
  background: linear-gradient(180deg, #121924 0%, rgba(18, 25, 36, 0) 700%);
  height: 100vh;
  z-index: 2;
  width: 100%;
  transition: 0.5s;
  overflow: hidden;

  .logo {
    position: absolute;
    top: 1%;
    left: 3%;

    img {
      width: 120px;
    }
  }

  .get_started {
    button {
      background: #ff8811;
      border-radius: 6px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      height: 36px;
      width: 120px;
      border: none;
    }
  }

  a.active {
    color: $color1;
  }
}

.SideBarContainer ul {
  margin-top: 56px;
  padding-left: 0rem;
  margin-bottom: 0px;
}

.SideBarContainer ul li {
  margin-bottom: 25px;
  position: relative;
  list-style: none;
  cursor: pointer;
  text-align: center;

  a {
    color: #fff;
    text-decoration: none;
    position: relative;
    padding-bottom: 5px;
    font-size: 16px;
  }
  .activeNav::after {
    content: "";
    position: absolute;
    left: 0%;
    bottom: 0%;
    height: 10%;
    width: 100%;
    background-color: #007bbc;
    border-radius: 5px;
  }
}

.SideBarContainer .header_btns {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.CrossBTn {
  position: absolute;
  top: 7%;
  right: 6.5%;
  cursor: pointer;
  height: 25px;
}

/* ==================== APPLY LOAN START ======================== */
.apply_loan_container {
  margin: 4rem 0rem;

  .apply_loan_right_container {
    background: #ff8b44;
    border-radius: 10px;
    min-height: 1px;
    height: 100%;
  }

  .to_be_paid {
    background: #fff;
    border-radius: 8px;

    button {
      width: 91px;
      height: 58px;
      background: #f1f1f1;
      border: 2px solid #000000;
      border-radius: 8px;
    }

    input {
      border-radius: 0px 8px 8px 0px;
    }
  }
}

.select {
  img {
    transform: rotate(90deg);
    transition: 0.3s;
  }

  &:hover {
    img {
      transform: rotate(-90deg);
    }

    // .options_container {
    //   display: block;
    // }
  }
}

.module-border-wrap {
  position: relative;
  background: #f1f1f1;
  padding: 1.4px;
  height: 58px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    transform: rotate(360deg);
    transition: 0.3s;
  }

  .options_container {
    z-index: 1;
    display: none;
    position: absolute;
    top: 3.75rem;
    left: 0%;
    width: 100%;
    background: #f1f1f1;
    border: none;
    backdrop-filter: blur(42px);
    border-radius: 3px 3px 5px 5px;
    color: $color4;
    max-height: 200px !important;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      // background: rgba(36, 40, 54, 1);
    }
    &::-webkit-scrollbar-thumb {
      background: $color4;
      border-radius: 10px;
    }

    li {
      transform: 0.5s;
      width: 100%;

      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &:hover {
        background-color: #ff8c4423;
      }
    }
  }

  &:hover {
    img {
      transform: rotate(180deg);
    }

    .options_container {
      display: block;
    }
  }
}

.inp_control {
  border-radius: 8px;
  background: #f1f1f1;
  height: 58px;
  width: 100%;
  border: none;
  font-size: 20px;
  color: #232323;
  padding: 0rem 1rem;
  font-weight: 500;
}

.inp_control_textarea {
  border-radius: 8px;
  background: #f1f1f1;
  width: 100%;
  border: none;
  font-size: 20px;
  color: #232323;
  padding: 0.6rem 1rem;
  font-weight: 500;
  resize: none;
}

.form_submit_btn {
  background: #000000;
  box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 60px;
  min-width: 460px;
  max-width: 460px;
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  border: none;
}

.pass_inp_container {
  .eye {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.form_submit_btn2 {
  background: #000000;
  box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 60px;
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  border: none;
}
/* ==================== APPLY LOAN END ======================== */

/* ==================== UPLOAD START ======================== */
.upload_container {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  border: 1px dashed #f1f1f1;
  border-radius: 10px;
  width: 100%;

  input {
    display: none;
  }

  label {
    height: 58px;
  }
}
/* ==================== UPLOAD END ======================== */

// MEDIA START
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
  .up_hero_container .position-relative,
  .up_hero_container {
    height: 100%;
  }
}
@media screen and (max-width: 575px) {
  .navigation_container {
    padding: 0.4rem 0rem;

    .header_btns {
      button {
        height: 28px;
      }
    }
  }
  .up_hero_container {
    padding: 0rem 2rem;
  }
  .contact_container {
    iframe {
      height: 300px;
    }
  }
  .header_container {
    img {
      // width: 40px;
    }
  }
  .form_submit_btn {
    min-width: 100%;
    max-width: 100%;
  }
  .thankyou_container .thankyou_container_inner {
    height: calc(100vh - 204px);
  }
  .upload_container p {
    font-size: 22px;
  }
  .form_submit_btn_up {
    width: 100%;
  }
}
// MEDIA END

.copyButton {
  padding: 5px;
  border: none;
  margin: 5px;
  border-radius: 5px;
  color: white;
  background-color: rgb(12, 170, 12);
}

.copyText {
  color: rgb(15, 184, 15);
  font-weight: 600;
}
.spinner {
  width: 70px !important;
  height: 70px !important;
  animation: move 1s ease-in-out 0.5s infinite forwards;
  border: 4px dotted rgb(236, 136, 21) !important;
  border-radius: 50%;
  margin: 0 auto;
  align-items: center;
  justify-self: center;
  margin: 0 auto;
  margin-top: 40vh;
  // animation: Spin 2 timing-function delay iteration-count direction fill-mode;
  box-shadow: -2px -2px 23px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: -2px -2px 23px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -2px -2px 23px 3px rgba(0, 0, 0, 0.25);
}
.spinner_title {
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  padding: 20px;
}

@keyframes move {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.previewImage {
  width: 100px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
  margin: 5px auto;
}

.otp-input {
  align-self: center;
  margin: 0 auto;
  input {
    height: 45px !important;
    width: 45px !important;
    outline: 1px solid #ff6100;
    border-radius: 5px;
    font-size: 24px;
    font-weight: 600;
  }
}

.logo {
  display: flex;
  align-items: center;
  padding: 5px;
  img {
    height: 90px;
    width: auto;
    align-items: flex-start;
  }
}
.header_container {
  align-items: center;
  h2 {
    color: #ffffff;
    font-size: 44px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif !important;
    p {
      font-family: "Roboto", sans-serif !important;

      font-size: 18px;
      color: #4c2603;
      text-transform: capitalize;
      padding: 0;
      margin: 0;
    }
  }
}
@media screen and (max-width: 600px) {
  .logo {
    padding: 0px;
    img {
      height: 60px;
    }

    h2 {
      font-size: 28px;
      p {
        font-size: 14px !important;
      }
    }
  }
}

.select {
  outline: none;
}

.about-container {
  background-color: #ff8b44;
  color: #fff;
  border-radius: 10px;
  padding: 3vw;
  .images{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      width: 90vw;
      max-width: 1000px;
      border-radius: 5px;
      margin: 2vw;
    }
  }

  ol {
    h3 {
      padding-left: 0;
      margin-left: 0;
    }
    li {
      padding: 1vw;
      margin-left: 1vw;
    }
    &:last-child {
      li {
        font-size: 18px;
      }
    }
  }
}

.buyCard {
  display: grid;
  height: 70px;
  border-radius: 5px;
  background-color: #f1f1f1;
  width: 100%;
  grid-template-columns: 3fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 2fr;
  margin: 5px;
  &.cashloan {
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  }
  &.owned {
    grid-template-columns: 3fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
  }
  > div {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: space-between;
    border: 0.5px solid rgb(201, 200, 200);
    .key {
      display: none;
    }
    input {
      padding: 5px;
      max-width: 100px;
      margin: 0 5px;
      &:increment {
        display: none;
      }
    }
  }
}
.successful {
  padding: 5px 10px;
  background-color: rgb(10, 180, 10);
  color: #fff;
}
.cancelled {
  padding: 5px 10px;
  background-color: rgb(214, 43, 43);
  color: #fff;
}
.que,
.pending {
  padding: 5px 10px;
  background-color: rgb(184, 184, 11);
  color: #fff;
}

.modalContent {
  padding: 2vw;
  position: relative;
  // width: 90vw;
  max-width: 800px;
  animation: zoomIn 0.4s ease-in-out;
  transition: all 0.3s;
  overflow-y: scroll;
  overflow-y: hidden;
  background-color: #fff;
  div {
    width: 100%;
    text-align: right;
    overflow: hidden;
  }
  button {
    background-color: #ff6100;
    color: #fff;
    margin-left: auto;
  }
  .modalClose {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 28px;
    &:hover {
      color: #ff6100;
      cursor: pointer;
    }
  }
}
.ReactModal__Content {
  width: max-content !important;
  max-width: 800px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.726);
}
.pdfContainer {
  width: 95vw;
  max-width: 1200px;
  margin: 2vw auto;
  height: calc(100vh - 100px);
  border-radius: 10px;
}
.pdfContainer iframe {
  width: 100%;
  height: 100%;
  padding: 2vw;
  padding-top: 3vw;
  object-fit: cover;
  * {
    max-width: 100%;
    max-width: fit-content;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (max-width: 800px) {
  .buyCard {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    flex-direction: column;
    &:first-child {
      display: none;
    }
    > div {
      height: 40px;
      flex: 1;
      width: 100%;
      border-bottom: 1px solid rgb(209, 207, 207);

      .key {
        display: inline-flex;
      }
    }
  }
  .modalContent {
    padding-top: 40px;
  }
}

.react-responsive-modal-modal {
  border-radius: 10px;
}
.web3modal-modal-container {
  z-index: 9999999;
}

.card-verify {
  width: 100%;
  .select-card {
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
    max-width: max-content;
    .card {
      height: 50px;
      border-radius: 10px;
      background-color: #ff6100;
      color: #f1f1f1;
      margin: 15px;
      padding: 10px;
      max-width: 300px;
      min-width: 250px;
      cursor: pointer;
    }
  }
  .photo-taker {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .card-placeholder {
      width: 80vw;
      height: 50vw;
      max-width: 400px;
      max-height: 300px;
      border-radius: 10px;
      background-color: #b9b9b9;
    }
    .selfie-placeholder {
      width: 80vw;
      height: 80vw;
      max-width: 500px;
      max-height: 500px;
      border-radius: 50%;
      object-fit: cover;
      margin: 2vw;
    }
  }
}
.display_image {
  width: 80vw;
  max-width: 300px;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}
.register {
  max-width: 600px;
  margin: auto;
  padding: 30px;
  background-color: $color1;
  border-radius: 10px;
}
.kyc_steps {
  display: flex;
  justify-content: center;
  .step {
    background-color: $color1;
    margin: 10px;
    padding: 10px 15px;
    color: #f1f1f1;
    font-weight: 600;
    border-radius: 10px;
    width: 80px;
    text-align: center;
  }
}
