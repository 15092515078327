@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;500;600;700&display=swap");
* {
  padding: 0%;
  margin: 0%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Inter", sans-serif;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

html {
  width: 100%;
  font-size: 100%;
  overflow-x: hidden;
}

@media screen and (max-width: 992px) {
  html {
    font-size: 92%;
  }
}

@media screen and (max-width: 668px) {
  html {
    font-size: 86%;
  }
}

@media screen and (max-width: 575px) {
  html {
    font-size: 82%;
  }
}

@media screen and (max-width: 475px) {
  html {
    font-size: 78%;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 75%;
  }
}

@media screen and (max-width: 280px) {
  html {
    font-size: 72%;
  }
}

.pointer {
  cursor: pointer;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(90.23deg, #ff6100 0%, #29d6dd 100%);
}

.f8 {
  font-size: 0.5rem;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875rem;
}

.f15 {
  font-size: 0.938rem;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f20 {
  font-size: 1.25rem;
}

.f22 {
  font-size: 1.375rem;
}

.f24 {
  font-size: 1.5rem;
}

.f28 {
  font-size: 1.75rem;
}

.f34 {
  font-size: 2.125rem;
}

.f30 {
  font-size: 1.875rem;
}

.f35 {
  font-size: 2.125rem;
  line-height: 57px;
}

.f50 {
  font-size: 3.125rem;
  line-height: 65px;
}

.f64 {
  font-size: 4rem;
  line-height: 71px;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.page_container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

body {
  background: url("../../public/assets/body-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.color1 {
  color: #ff6100;
}

.bg_color1 {
  background: #ff6100;
}

.color2 {
  color: #1a1b1c;
}

.bg_color2 {
  background: #1a1b1c;
}

.color3 {
  color: #504f4f;
}

.bg_color3 {
  background: #504f4f;
}

.color4 {
  color: #160d0d;
  font-weight: 600;
}

.bg_color4 {
  background: #160d0d;
}

.color5 {
  color: #606060;
}

.bg_color5 {
  background: #606060;
}

.header_container {
  background: linear-gradient(90.23deg, #ff6100 0%, #29d6dd 100%);
}

.header_container h1 {
  font-weight: 700;
}

.top_navigation {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  z-index: 3;
}

.under_navigation {
  height: 97px;
}

.up_hero_container {
  background: url("../../public/assets/body-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 97px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.up_hero_container .lets_start {
  background: #ff6100;
  border: 2px solid #ffffff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  color: #ffffff;
  border-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding: 0.5rem 2rem;
}

.up_hero_container h3 {
  font-weight: 700;
  padding-top: 50px;
  font-family: "Ibarra Real Nova", serif !important;
}

.up_hero_container h3 h5 {
  display: inline-block;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit !important;
}

.up_hero_container .position-relative {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: calc(100vh - 125px);
}

.up_hero_container .trnsact_with_container {
  position: absolute;
  bottom: 10%;
  left: 0%;
  background: #fff;
  padding: 1rem 2rem;
  width: 90%;
  border-radius: 0px 10px 10px 0px;
}

.navigation_container {
  background: #1a1b1c;
}

.navigation_container .active_navlink {
  background: #ff6100;
}

.navigation_container .header_btns button:nth-child(2) {
  background: #ff620051;
  border: 2px solid #ff6100;
  border-radius: 4px;
  color: #fff;
}

.navigation_container .hamb_cross {
  width: 35px;
}

.hero_container .mySwiper {
  margin-top: 2rem;
  -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ff6100;
}

.hero_container .mySwiper .single_swiper {
  padding: 3rem;
  min-height: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.hero_container .mySwiper .single_swiper img[alt="Get 80% Loan on your Crypto Assets"] {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.hero_container .mySwiper .single_swiper img {
  width: 100%;
}

.hero_container .mySwiper .single_swiper .lets_start {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #ffffff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  color: #ffffff;
  border-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding: 0.5rem 2rem;
}

.hero_container .mySwiper .swiper-pagination-bullet {
  background: #fff;
  opacity: 1;
  margin: 0px 3px !important;
}

.hero_container .mySwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 44px;
  border-radius: 50px;
}

.transact_container {
  margin-top: 1rem;
}

.contact_container {
  margin-top: 1rem;
}

.contact_container input {
  height: 50px;
}

.contact_container .submit {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #ffffff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  color: #ffffff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding: 0.5rem 2rem;
}

.footer_container {
  margin-top: 2rem;
  background: linear-gradient(90.23deg, #ff6100 0%, #29d6dd 100%);
  padding: 2rem 0rem;
}

.how_it_works {
  background: #ffffff;
  border-radius: 10px;
  min-height: 1px;
  height: 100%;
}

.thankyou_container {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.thankyou_container .thankyou_container_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #ffffff;
  border-radius: 10px;
  min-height: calc(100vh - 225px);
  margin-top: 5rem;
  padding: 2rem 0rem;
}

/* ==================== SideBAR STYLING END ======================== */
.SideBarContainer {
  margin-top: 97px;
  position: fixed;
  right: 0%;
  left: 0%;
  bottom: 0%;
  color: white;
  background: -webkit-gradient(linear, left top, left bottom, from(#121924), color-stop(700%, rgba(18, 25, 36, 0)));
  background: linear-gradient(180deg, #121924 0%, rgba(18, 25, 36, 0) 700%);
  height: 100vh;
  z-index: 2;
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
}

.SideBarContainer .logo {
  position: absolute;
  top: 1%;
  left: 3%;
}

.SideBarContainer .logo img {
  width: 120px;
}

.SideBarContainer .get_started button {
  background: #ff8811;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  height: 36px;
  width: 120px;
  border: none;
}

.SideBarContainer a.active {
  color: #ff6100;
}

.SideBarContainer ul {
  margin-top: 56px;
  padding-left: 0rem;
  margin-bottom: 0px;
}

.SideBarContainer ul li {
  margin-bottom: 25px;
  position: relative;
  list-style: none;
  cursor: pointer;
  text-align: center;
}

.SideBarContainer ul li a {
  color: #fff;
  text-decoration: none;
  position: relative;
  padding-bottom: 5px;
  font-size: 16px;
}

.SideBarContainer ul li .activeNav::after {
  content: "";
  position: absolute;
  left: 0%;
  bottom: 0%;
  height: 10%;
  width: 100%;
  background-color: #007bbc;
  border-radius: 5px;
}

.SideBarContainer .header_btns {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.CrossBTn {
  position: absolute;
  top: 7%;
  right: 6.5%;
  cursor: pointer;
  height: 25px;
}

/* ==================== APPLY LOAN START ======================== */
.apply_loan_container {
  margin: 4rem 0rem;
}

.apply_loan_container .apply_loan_right_container {
  background: #ff8b44;
  border-radius: 10px;
  min-height: 1px;
  height: 100%;
}

.apply_loan_container .to_be_paid {
  background: #fff;
  border-radius: 8px;
}

.apply_loan_container .to_be_paid button {
  width: 91px;
  height: 58px;
  background: #f1f1f1;
  border: 2px solid #000000;
  border-radius: 8px;
}

.apply_loan_container .to_be_paid input {
  border-radius: 0px 8px 8px 0px;
}

.select img {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.select:hover img {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.module-border-wrap {
  position: relative;
  background: #f1f1f1;
  padding: 1.4px;
  height: 58px;
  border-radius: 8px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.module-border-wrap img {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.module-border-wrap .options_container {
  z-index: 1;
  display: none;
  position: absolute;
  top: 3.75rem;
  left: 0%;
  width: 100%;
  background: #f1f1f1;
  border: none;
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  border-radius: 3px 3px 5px 5px;
  color: #160d0d;
  max-height: 200px !important;
  overflow-y: auto;
}

.module-border-wrap .options_container::-webkit-scrollbar {
  width: 10px;
}

.module-border-wrap .options_container::-webkit-scrollbar-thumb {
  background: #160d0d;
  border-radius: 10px;
}

.module-border-wrap .options_container li {
  -webkit-transform: 0.5s;
          transform: 0.5s;
  width: 100%;
}

.module-border-wrap .options_container li div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.module-border-wrap .options_container li:hover {
  background-color: #ff8c4423;
}

.module-border-wrap:hover img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.module-border-wrap:hover .options_container {
  display: block;
}

.inp_control {
  border-radius: 8px;
  background: #f1f1f1;
  height: 58px;
  width: 100%;
  border: none;
  font-size: 20px;
  color: #232323;
  padding: 0rem 1rem;
  font-weight: 500;
}

.inp_control_textarea {
  border-radius: 8px;
  background: #f1f1f1;
  width: 100%;
  border: none;
  font-size: 20px;
  color: #232323;
  padding: 0.6rem 1rem;
  font-weight: 500;
  resize: none;
}

.form_submit_btn {
  background: #000000;
  -webkit-box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 60px;
  min-width: 460px;
  max-width: 460px;
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  border: none;
}

.pass_inp_container .eye {
  position: absolute;
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.form_submit_btn2 {
  background: #000000;
  -webkit-box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 60px;
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  border: none;
}

/* ==================== APPLY LOAN END ======================== */
/* ==================== UPLOAD START ======================== */
.upload_container {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  border: 1px dashed #f1f1f1;
  border-radius: 10px;
  width: 100%;
}

.upload_container input {
  display: none;
}

.upload_container label {
  height: 58px;
}

/* ==================== UPLOAD END ======================== */
@media screen and (max-width: 768px) {
  .up_hero_container .position-relative,
  .up_hero_container {
    height: 100%;
  }
}

@media screen and (max-width: 575px) {
  .navigation_container {
    padding: 0.4rem 0rem;
  }
  .navigation_container .header_btns button {
    height: 28px;
  }
  .up_hero_container {
    padding: 0rem 2rem;
  }
  .contact_container iframe {
    height: 300px;
  }
  .form_submit_btn {
    min-width: 100%;
    max-width: 100%;
  }
  .thankyou_container .thankyou_container_inner {
    height: calc(100vh - 204px);
  }
  .upload_container p {
    font-size: 22px;
  }
  .form_submit_btn_up {
    width: 100%;
  }
}

.copyButton {
  padding: 5px;
  border: none;
  margin: 5px;
  border-radius: 5px;
  color: white;
  background-color: #0caa0c;
}

.copyText {
  color: #0fb80f;
  font-weight: 600;
}

.spinner {
  width: 70px !important;
  height: 70px !important;
  -webkit-animation: move 1s ease-in-out 0.5s infinite forwards;
          animation: move 1s ease-in-out 0.5s infinite forwards;
  border: 4px dotted #ec8815 !important;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-column-align: center;
      justify-self: center;
  margin: 0 auto;
  margin-top: 40vh;
  box-shadow: -2px -2px 23px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: -2px -2px 23px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -2px -2px 23px 3px rgba(0, 0, 0, 0.25);
}

.spinner_title {
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  padding: 20px;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes move {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.previewImage {
  width: 100px;
  height: 80px;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 5px auto;
}

.otp-input {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 0 auto;
}

.otp-input input {
  height: 45px !important;
  width: 45px !important;
  outline: 1px solid #ff6100;
  border-radius: 5px;
  font-size: 24px;
  font-weight: 600;
}

.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px;
}

.logo img {
  height: 90px;
  width: auto;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.header_container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header_container h2 {
  color: #ffffff;
  font-size: 44px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif !important;
}

.header_container h2 p {
  font-family: "Roboto", sans-serif !important;
  font-size: 18px;
  color: #4c2603;
  text-transform: capitalize;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 600px) {
  .logo {
    padding: 0px;
  }
  .logo img {
    height: 60px;
  }
  .logo h2 {
    font-size: 28px;
  }
  .logo h2 p {
    font-size: 14px !important;
  }
}

.select {
  outline: none;
}

.about-container {
  background-color: #ff8b44;
  color: #fff;
  border-radius: 10px;
  padding: 3vw;
}

.about-container .images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about-container .images img {
  width: 90vw;
  max-width: 1000px;
  border-radius: 5px;
  margin: 2vw;
}

.about-container ol h3 {
  padding-left: 0;
  margin-left: 0;
}

.about-container ol li {
  padding: 1vw;
  margin-left: 1vw;
}

.about-container ol:last-child li {
  font-size: 18px;
}

.buyCard {
  display: -ms-grid;
  display: grid;
  height: 70px;
  border-radius: 5px;
  background-color: #f1f1f1;
  width: 100%;
  -ms-grid-columns: 3fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 2fr;
      grid-template-columns: 3fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 2fr;
  margin: 5px;
}

.buyCard.cashloan {
  -ms-grid-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
      grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}

.buyCard.owned {
  -ms-grid-columns: 3fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
      grid-template-columns: 3fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
}

.buyCard > div {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 0.5px solid #c9c8c8;
}

.buyCard > div .key {
  display: none;
}

.buyCard > div input {
  padding: 5px;
  max-width: 100px;
  margin: 0 5px;
}

.buyCard > div input:increment {
  display: none;
}

.successful {
  padding: 5px 10px;
  background-color: #0ab40a;
  color: #fff;
}

.cancelled {
  padding: 5px 10px;
  background-color: #d62b2b;
  color: #fff;
}

.que,
.pending {
  padding: 5px 10px;
  background-color: #b8b80b;
  color: #fff;
}

.modalContent {
  padding: 2vw;
  position: relative;
  max-width: 800px;
  -webkit-animation: zoomIn 0.4s ease-in-out;
          animation: zoomIn 0.4s ease-in-out;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow-y: scroll;
  overflow-y: hidden;
  background-color: #fff;
}

.modalContent div {
  width: 100%;
  text-align: right;
  overflow: hidden;
}

.modalContent button {
  background-color: #ff6100;
  color: #fff;
  margin-left: auto;
}

.modalContent .modalClose {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 28px;
}

.modalContent .modalClose:hover {
  color: #ff6100;
  cursor: pointer;
}

.ReactModal__Content {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  max-width: 800px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.726);
}

.pdfContainer {
  width: 95vw;
  max-width: 1200px;
  margin: 2vw auto;
  height: calc(100vh - 100px);
  border-radius: 10px;
}

.pdfContainer iframe {
  width: 100%;
  height: 100%;
  padding: 2vw;
  padding-top: 3vw;
  -o-object-fit: cover;
     object-fit: cover;
}

.pdfContainer iframe * {
  max-width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 800px) {
  .buyCard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .buyCard:first-child {
    display: none;
  }
  .buyCard > div {
    height: 40px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 100%;
    border-bottom: 1px solid #d1cfcf;
  }
  .buyCard > div .key {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .modalContent {
    padding-top: 40px;
  }
}

.react-responsive-modal-modal {
  border-radius: 10px;
}

.web3modal-modal-container {
  z-index: 9999999;
}

.card-verify {
  width: 100%;
}

.card-verify .select-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: auto;
  text-align: center;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.card-verify .select-card .card {
  height: 50px;
  border-radius: 10px;
  background-color: #ff6100;
  color: #f1f1f1;
  margin: 15px;
  padding: 10px;
  max-width: 300px;
  min-width: 250px;
  cursor: pointer;
}

.card-verify .photo-taker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.card-verify .photo-taker .card-placeholder {
  width: 80vw;
  height: 50vw;
  max-width: 400px;
  max-height: 300px;
  border-radius: 10px;
  background-color: #b9b9b9;
}

.card-verify .photo-taker .selfie-placeholder {
  width: 80vw;
  height: 80vw;
  max-width: 500px;
  max-height: 500px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 2vw;
}

.display_image {
  width: 80vw;
  max-width: 300px;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 10px;
}

.register {
  max-width: 600px;
  margin: auto;
  padding: 30px;
  background-color: #ff6100;
  border-radius: 10px;
}

.kyc_steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.kyc_steps .step {
  background-color: #ff6100;
  margin: 10px;
  padding: 10px 15px;
  color: #f1f1f1;
  font-weight: 600;
  border-radius: 10px;
  width: 80px;
  text-align: center;
}
